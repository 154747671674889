import { useState } from 'react'
import { toast } from 'react-hot-toast'
import Link from 'next/link'
import PropTypes from 'prop-types'

import { CoreApi } from '/src/helpers/CoreApi'
import Container from '/src/components/@common.blocks/Container'
import Button from '/src/components/@common.blocks/Button'
import TableCollections from '/src/components/@common.blocks/TableCollections'

const TableExtra = ({ sort, periodOnClick }) => {
  return (
    <div className='top-collections__filter'>
      <Button
        color={sort === 'volumeD' ? 'primary' : 'default'}
        onClick={(e) => periodOnClick(e, 'volumeD')}
      >
        Today
      </Button>
      <Button
        color={sort === 'volumeW' ? 'primary' : 'default'}
        onClick={(e) => periodOnClick(e, 'volumeW')}
      >
        7 days
      </Button>
      <Button
        color={sort === 'volumeM' ? 'primary' : 'default'}
        onClick={(e) => periodOnClick(e, 'volumeM')}
      >
        30 days
      </Button>
    </div>
  )
}

const TopCollections = (props) => {
  const [data, setData] = useState([
    ...props.featuredCollections.slice(0, 1),
    ...props.collections,
  ])
  const [sort, setSort] = useState('volumeD')

  const periodOnClick = async (e, fieldKey) => {
    try {
      const collections = await CoreApi.getCollections({
        sort: fieldKey,
        size: 5,
      })
      setData(collections)
      setSort(fieldKey)
    } catch (error) {
      console.error(error)
      toast.error(error.message)
    }
  }

  return (
    <section className='top-collections' id='top-collections'>
      <Container>
        <TableCollections
          title='Top Collections'
          tableExtra={<TableExtra sort={sort} periodOnClick={periodOnClick} />}
          data={data}
          sort={sort}
        />
        <div className='top-collections__button'>
          <Link href='/collections'>
            <a className='button button_color_default button_size_md'>
              Explore more
            </a>
          </Link>
        </div>
      </Container>
    </section>
  )
}

TopCollections.defaultProps = {
  collections: [],
  featuredCollections: [],
}

TopCollections.propTypes = {
  collections: PropTypes.array,
  featuredCollections: PropTypes.array,
}

export default TopCollections
