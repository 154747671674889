import { useRouter } from 'next/router'
import NumberFormat from 'react-number-format'
import Image from 'next/image'

import Table from '/src/components/@common.blocks/Table'

import Text from '/src/components/@common.blocks/Text'
import Avatar from '/src/components/@common.blocks/Avatar'
import BlueMarkBadge from '/src/components/@common.blocks/Badge/BlueMarkBadge.js'
import Skeleton from '/src/components/@common.blocks/Skeleton'
import { formatNumber, cutText } from '/src/helpers/formatter'
import blockchainIcons from '/src/constants/blockchainIcons'
import blockchainSymbols from '/src/constants/blockchainSymbols'

const sortKeys = {
  volumeD: '1d',
  volumeW: '7d',
  volumeM: '30d',
}

const TableCollections = ({ title, data, tableExtra, sort }) => {
  const router = useRouter()
  return (
    <Table
      title={title}
      extra={tableExtra}
      data={data}
      columns={[
        {
          id: 'logo',
          title: '',
          render: (row, index) => (
            <div className='table-collections__logo'>
              <Text component='span' weight='bold' color='light-gray'>
                {index + 1}
              </Text>
              {row.loading && <Skeleton.Avatar active={true} loading={true} />}
              {!row.loading && (
                <Avatar
                  src={row.image}
                  alt={row.slug || row.contract}
                  link={`/collections/${row.slug || row.contract}`}
                />
              )}
              <div className='table-collections__name'>
                {row.loading && <Skeleton.Input active={true} loading={true} />}
                {!row.loading && (
                  <>
                    <BlueMarkBadge visible={row.verified}>
                      <Text
                        component='span'
                        weight='medium'
                        color={row.featured ? 'gold' : 'default'}
                      >
                        {cutText(row.name, 20)}
                      </Text>
                    </BlueMarkBadge>
                    <Text
                      component='span'
                      weight='medium'
                      color='light-gray'
                      className='table-collections__floor'
                    >
                      {`Floor: ${formatNumber(row.stats?.floorPrice) || '-'} ${
                        blockchainSymbols[row.blockchain]
                      }`}
                    </Text>
                  </>
                )}
              </div>
            </div>
          ),
        },
        {
          id: `stats.${sort}`,
          title: `Volume ${sortKeys[sort] || ''}`,
          type: 'number',
          decimals: 2,
          render: (row) => {
            if (row.loading) {
              return (
                <Text component='span' color='light-gray'>
                  --
                </Text>
              )
            }

            let changeVolume
            switch (sort) {
              case 'volumeD': {
                changeVolume = row.stats ? row.stats.changeD : 0
                break
              }
              case 'volumeW': {
                changeVolume = row.stats ? row.stats.changeW : 0
                break
              }
              case 'volumeM': {
                changeVolume = row.stats ? row.stats.changeM : 0
                break
              }
            }
            return (
              <div className='table-collections__stats-field'>
                <Text component='span'>
                  <Image
                    src={blockchainIcons[row.blockchain]}
                    alt={row.blockchain}
                    width={14}
                    height={14}
                  />
                  <NumberFormat
                    value={formatNumber(row.stats ? row.stats[sort] : 0, 0)}
                    displayType='text'
                    thousandSeparator={true}
                    defaultValue='-'
                  />
                </Text>
                {changeVolume && (
                  <Text
                    component='span'
                    color={changeVolume > 0 ? 'positive' : 'negative'}
                    size='sm'
                    className='table-collections__stats-percent'
                  >
                    {`${changeVolume < 0 ? '' : '+'}${formatNumber(
                      changeVolume
                    )}%`}
                  </Text>
                )}
              </div>
            )
          },
        },
        {
          id: 'stats.totalVolume',
          title: 'Total Volume',
          type: 'number',
          decimals: 2,
          render: (row) => {
            if (row.loading) {
              return (
                <Text component='span' color='light-gray'>
                  --
                </Text>
              )
            }

            return (
              <div className='table-collections__stats-field'>
                <Image
                  src={blockchainIcons[row.blockchain]}
                  alt={row.blockchain}
                  width={14}
                  height={14}
                />
                <Text component='span'>
                  <NumberFormat
                    value={formatNumber(row.stats?.totalVolume, 0)}
                    displayType='text'
                    thousandSeparator={true}
                    defaultValue='-'
                  />
                </Text>
              </div>
            )
          },
          responsive: ['md'],
        },
        {
          id: 'stats.avgPrice',
          title: 'Floor Price',
          type: 'number',
          decimals: 2,
          render: (row) => {
            if (row.loading) {
              return (
                <Text component='span' color='light-gray'>
                  --
                </Text>
              )
            }

            return (
              <div className='table-collections__stats-field'>
                <Image
                  src={blockchainIcons[row.blockchain]}
                  alt={row.blockchain}
                  width={14}
                  height={14}
                />
                <Text component='span'>
                  {formatNumber(row.stats?.floorPrice) || '-'}
                </Text>
              </div>
            )
          },
          responsive: ['md'],
        },
        {
          id: 'stats.owners',
          title: 'Owners',
          type: 'number',
          render: (row) => {
            if (row.loading) {
              return (
                <Text component='span' color='light-gray'>
                  --
                </Text>
              )
            }

            return (
              <div>
                <Text component='span'>
                  <NumberFormat
                    value={formatNumber(row.stats?.owners, 0)}
                    displayType='text'
                    thousandSeparator={true}
                    defaultValue='-'
                  />
                </Text>
              </div>
            )
          },
          responsive: ['md'],
        },
      ]}
      onRowClick={(e, row) => router.push(`/collections/${row.slug}`)}
    />
  )
}

export default TableCollections
