import PropTypes from 'prop-types'

const Col = ({ children, xs, sm, md, lg, xl }) => {
  const classNameCol =
    'g-col' +
    (xs ? ` g-col-xs-${xs}` : '') +
    (sm ? ` g-col-sm-${sm}` : '') +
    (md ? ` g-col-md-${md}` : '') +
    (lg ? ` g-col-lg-${lg}` : '') +
    (xl ? ` g-col-xl-${xl}` : '')

  return <div className={classNameCol}>{children}</div>
}

Col.propTypes = {
  children: PropTypes.any,
  xs: PropTypes.oneOf([1, 2, 3, 4, 6, 8, 9, 12]),
  sm: PropTypes.oneOf([1, 2, 3, 4, 6, 8, 9, 12]),
  md: PropTypes.oneOf([1, 2, 3, 4, 6, 8, 9, 12]),
  lg: PropTypes.oneOf([1, 2, 3, 4, 6, 8, 9, 12]),
  xl: PropTypes.oneOf([1, 2, 3, 4, 6, 8, 9, 12]),
}

export default Col
