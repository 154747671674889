import PropTypes from 'prop-types'

import {
  DiscordIcon,
  TwitterIcon,
  WebsiteIcon,
} from '/src/components/@common.blocks/Icon'
import Text from '/src/components/@common.blocks/Text'
import Tag from '/src/components/@common.blocks/Tag'

const CardFooterMobile = (props) => {
  const { website, twitter, discord } = props
  return (
    <footer className='upcoming-card-mobile__footer'>
      <Text
        weight='bold'
        color='light-gray'
        className='upcoming-card-mobile__subtitle'
      >
        Links
      </Text>
      <div className='upcoming-card-mobile__grid'>
        {website && (
          <a
            href={website}
            rel='noreferrer'
            target='_blank'
            className='upcoming-card-mobile__grid-item'
            aria-label='Official site'
          >
            <Tag>
              <WebsiteIcon />
            </Tag>
          </a>
        )}
        {twitter && (
          <a
            href={`https://twitter.com/${twitter}`}
            rel='noreferrer'
            target='_blank'
            className='upcoming-card-mobile__grid-item'
            aria-label='Twitter'
          >
            <Tag>
              <TwitterIcon />
            </Tag>
          </a>
        )}
        {discord && (
          <a
            href={discord}
            rel='noreferrer'
            target='_blank'
            className='upcoming-card-mobile__grid-item'
            aria-label='Discord'
          >
            <Tag>
              <DiscordIcon />
            </Tag>
          </a>
        )}
      </div>
    </footer>
  )
}

const CardFooter = ({ website, twitter, discord, isMobile, loading }) => {
  if (isMobile)
    return (
      <CardFooterMobile website={website} twitter={twitter} discord={discord} />
    )
  return (
    <footer className='upcoming-card__footer'>
      <div className='upcoming-card__footer-links'>
        {website && (
          <a
            href={website}
            rel='noreferrer'
            target='_blank'
            aria-label='Official site'
          >
            <WebsiteIcon />
          </a>
        )}
        {twitter && (
          <a
            href={`https://twitter.com/${twitter}`}
            rel='noreferrer'
            target='_blank'
            aria-label='Twitter'
          >
            <TwitterIcon />
          </a>
        )}
        {discord && (
          <a
            href={discord}
            rel='noreferrer'
            target='_blank'
            aria-label='Discord'
          >
            <DiscordIcon />
          </a>
        )}
      </div>
    </footer>
  )
}

CardFooter.propTypes = {
  website: PropTypes.string,
  twitter: PropTypes.string,
  discord: PropTypes.string,
  isMobile: PropTypes.bool,
  loading: PropTypes.bool,
}

export default CardFooter
