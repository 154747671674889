import Link from 'next/link'

import Container from '/src/components/@common.blocks/Container'
import Title from '/src/components/@common.blocks/Title'
import UpcomingCard from '/src/components/@common.blocks/UpcomingCard'
import Grid from '/src/components/@common.blocks/Grid'

const Upcoming = ({ data = [] }) => {
  if (data.length === 0) return ''
  return (
    <section className='upcoming' id='upcoming'>
      <Container>
        <Title level={2} className='section__title text-left'>
          Drops Calendar
        </Title>
        <Grid gutter={{ xs: 2, sm: 3 }}>
          {data.map((collection, key) => (
            <Grid.Col key={key} xs={6} sm={4} xl={3}>
              <UpcomingCard collection={collection} />
            </Grid.Col>
          ))}
        </Grid>
        <div className='upcoming__link'>
          <Link href='/upcoming'>
            <a className='button button_color_primary button_size_md'>
              Explore more
            </a>
          </Link>
        </div>
      </Container>
    </section>
  )
}

export default Upcoming
