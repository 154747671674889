import PropTypes from 'prop-types'
import Image from 'next/image'

import Text from '/src/components/@common.blocks/Text'
import Tag from '/src/components/@common.blocks/Tag'
import { cutText } from '/src/helpers/formatter'
import blockchainIcons from '/src/constants/blockchainIcons'

const CardHeaderMobile = ({
  name,
  featured,
  saleDate,
  saleTime,
  blockchain,
  mintPrice,
  supply,
}) => {
  return (
    <header className='upcoming-card-mobile__header'>
      <Text
        size='md'
        weight='bold'
        color={featured ? 'gold' : 'secondary'}
        className='upcoming-card-mobile__name'
      >
        {cutText(name, 20)}
      </Text>
      <div className='upcoming-card-mobile__grid'>
        <div className='upcoming-card-mobile__grid-item'>
          <Tag>{saleDate}</Tag>
        </div>
        <div className='upcoming-card-mobile__grid-item'>
          <Tag>{saleTime}</Tag>
        </div>
        <div className='upcoming-card-mobile__grid-item'>
          <Tag>
            <Image
              src={blockchainIcons[blockchain]}
              alt={blockchain}
              height={14}
              width={14}
            />
            {mintPrice}
          </Tag>
        </div>
        <div className='upcoming-card-mobile__grid-item'>
          <Tag>{supply}</Tag>
        </div>
      </div>
    </header>
  )
}

const CardHeader = (props) => {
  if (props.isMobile) return <CardHeaderMobile {...props} />
  const {
    name,
    featured,
    saleDate,
    saleTime,
    blockchain,
    mintPrice,
    supply,
    isMobile,
  } = props
  return (
    <header className='upcoming-card__header'>
      <Text size='md' weight='medium' color={featured ? 'gold' : 'default'}>
        {cutText(name, 25)}
      </Text>
      <ul className='upcoming-card__metadata'>
        <li className='upcoming-card__metadata-item'>
          <Text component='span'>{saleDate}</Text>
          <Text component='span'>{saleTime}</Text>
        </li>
        <li className='upcoming-card__metadata-item'>
          <Text component='span'>
            <Image
              src={blockchainIcons[blockchain]}
              alt={blockchain}
              height={14}
              width={14}
            />
            {mintPrice}
          </Text>
          <Text component='span'>{supply}</Text>
        </li>
      </ul>
    </header>
  )
}

CardHeader.defaultProps = {
  name: '',
  featured: false,
}

CardHeader.propTypes = {
  name: PropTypes.string.isRequired,
  featured: PropTypes.bool,
  saleDate: PropTypes.string,
  saleTime: PropTypes.string,
  blockchain: PropTypes.string,
  mintPrice: PropTypes.number,
  supply: PropTypes.number,
}

export default CardHeader
