import Image from 'next/image'

import Container from '/src/components/@common.blocks/Container'
import Title from '/src/components/@common.blocks/Title'
import Text from '/src/components/@common.blocks/Text'

const ChromeExtension = () => {
  return (
    <section className='chrome-extension section-main'>
      <Container className='chrome-extension__container'>
        <div className='chrome-extension__wrapper'>
          <Image src='/icons/chrome.svg' alt='chrome' width={60} height={60} />
          <Title level={2} className='chrome-extension__title'>
            Chrome Extension
          </Title>
          <Text className='chrome-extension__text'>
            The fastest way to check the NFTs rarity on the OpenSea, Rarible,
            Looksrare and other marketplaces with our chrome extension
          </Text>
          <div className='chrome-extension__link'>
            <a
              href='https://chrome.google.com/webstore/detail/rariscan/gfnjpncfnjpccgledppefkohmahhecil?hl=en-GB'
              target='_blank'
              rel='noreferrer'
            >
              Open Extension
            </a>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default ChromeExtension
