import Container from '/src/components/@common.blocks/Container'
import Title from '/src/components/@common.blocks/Title'
import Text from '/src/components/@common.blocks/Text'

const Rabbitzz = () => {
  return (
    <section className='rabbitzz section-main'>
      <Container className='rabbitzz__container'>
        <div className='rabbitzz__bg' />
        <div className='rabbitzz__wrapper'>
          <Title level={2} className='rabbitzz__title'>
            Rabbitzz NFT = <br />
            Rariscan Key
          </Title>
          <Text className='rabbitzz__text'>
            Whitelist spots raffle is open!
            <br />
            Follow us on Twitter keep up to date with our latest updates and
            announcements.
          </Text>
          <div className='rabbitzz__links'>
            <a
              href='https://rariscan.io/whitelist/rabbitzz'
              target='_blank'
              rel='noreferrer'
              className='rabbitzz__link'
            >
              <img src='/icons/website.svg' alt='website' />
            </a>
            <a
              href='https://twitter.com/rariscan'
              target='_blank'
              rel='noreferrer'
              className='rabbitzz__link'
            >
              <img src='/icons/twitter.svg' alt='twitter' />
            </a>
            {/*<a*/}
            {/*  href='https://discord.gg/mUP5sQXkWz'*/}
            {/*  target='_blank'*/}
            {/*  rel='noreferrer'*/}
            {/*  className='rabbitzz__link'*/}
            {/*>*/}
            {/*  <img src='/icons/discord.svg' alt='discord' />*/}
            {/*</a>*/}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Rabbitzz
