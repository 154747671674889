import { useState } from 'react'
import Image from 'next/image'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import numeral from 'numeral'
import Link from 'next/link'

import Avatar from '/src/components/@common.blocks/Avatar'
import Title from '/src/components/@common.blocks/Title'
import Text from '/src/components/@common.blocks/Text'
import Grid from '/src/components/@common.blocks/Grid'
import Skeleton from '/src/components/@common.blocks/Skeleton'
import { cutText, formatNumber } from '/src/helpers/formatter'
import blockchainIcons from '/src/constants/blockchainIcons'

const LatestCard = ({ data }) => {
  const [loading, setLoading] = useState(!!data.bannerImage)
  const {
    image,
    bannerImage,
    name,
    verified,
    createdAt,
    blockchain,
    slug,
    contract,
  } = data
  const date = `Added on ${moment(createdAt).format('D MMM')}`
  return (
    <Link href={`/collections/${slug || contract}`}>
      <article className='latest-card'>
        <div className='latest-card__cover'>
          <Skeleton loading={loading}>
            {bannerImage && (
              <Image
                src={bannerImage}
                alt={name}
                layout='fill'
                className='latest-card__cover-img'
                onLoadingComplete={() => setLoading(false)}
              />
            )}
          </Skeleton>
        </div>
        <Avatar
          src={image}
          border='white'
          size='md'
          alt={name}
          className='latest-card__logo'
        />
        <header className='latest-card__header'>
          <Title level={4} className='latest-card__title'>
            {cutText(name, 20)}
            {verified && (
              <img
                src='/icons/bluemark.svg'
                alt='bluemark'
                className='bluemark-badge'
              />
            )}
          </Title>
          <Text
            size='sm'
            color='light-gray'
            weight='medium'
            className='text-center'
          >
            {date}
          </Text>
        </header>
        <footer className='latest-card__footer'>
          <div className='latest-card__stats'>
            <Grid gutter={1}>
              <Grid.Col xs={4} sm={4}>
                <Text
                  size='sm'
                  color='light-gray'
                  weight='medium'
                  className='text-center'
                >
                  Total
                </Text>
                <Text
                  size='sm'
                  weight='medium'
                  className='text-center latest-card__stats-item'
                >
                  <Image
                    src={blockchainIcons[blockchain]}
                    alt={blockchain}
                    width={14}
                    height={14}
                  />
                  <span>{numeral(data.stats?.totalVolume).format('0a')}</span>
                </Text>
              </Grid.Col>
              <Grid.Col xs={4} sm={4}>
                <Text
                  size='sm'
                  color='light-gray'
                  weight='medium'
                  className='text-center'
                >
                  Floor
                </Text>
                <Text
                  size='sm'
                  weight='medium'
                  className='text-center latest-card__stats-item'
                >
                  <Image
                    src={blockchainIcons[blockchain]}
                    alt={blockchain}
                    width={14}
                    height={14}
                  />
                  <NumberFormat
                    value={formatNumber(data.stats?.floorPrice, 2)}
                    displayType='text'
                    thousandSeparator={true}
                    defaultValue='-'
                  />
                </Text>
              </Grid.Col>
              <Grid.Col xs={4} sm={4}>
                <Text
                  size='sm'
                  color='light-gray'
                  weight='medium'
                  className='text-center'
                >
                  Supply
                </Text>
                <Text
                  size='sm'
                  weight='medium'
                  className='text-center latest-card__stats-item'
                >
                  {numeral(data.stats?.totalSupply).format('0.[0]a')}
                </Text>
              </Grid.Col>
            </Grid>
          </div>
        </footer>
      </article>
    </Link>
  )
}

export default LatestCard
