import Image from 'next/image'
import Container from '/src/components/@common.blocks/Container'
import Text from '/src/components/@common.blocks/Text'

import collectionsImg from '/public/images/collections-stats.png'
import upcomingImg from '/public/images/upcoming-stats.png'
import rankedImg from '/public/images/ranked-nfts.png'

const MainStats = () => {
  return (
    <section className='main-stats'>
      <Container className='main-stats__wrapper'>
        <div className='main-stats__item'>
          <Image
            src={collectionsImg}
            alt='collections'
            width={100}
            height={40}
            className='main-stats__item-img'
          />
          <Text size='lg' className='text-center' weight='medium'>
            3k+ NFT Collections
          </Text>
        </div>
        <div className='main-stats__item'>
          <Image
            src={upcomingImg}
            alt='upcoming'
            width={100}
            height={40}
            className='main-stats__item-img'
          />
          <Text size='lg' className='text-center' weight='medium'>
            1k+ best upcoming drops
          </Text>
        </div>
        <div className='main-stats__item'>
          <Image
            src={rankedImg}
            alt='collections'
            width={100}
            height={40}
            className='main-stats__item-img'
          />
          <Text size='lg' className='text-center' weight='medium'>
            Over 4 million ranked NFTs
          </Text>
        </div>
      </Container>
    </section>
  )
}

export default MainStats
