import { CoreApi } from '/src/helpers/CoreApi'

import Layout from '/src/components/@common.blocks/Layout'
import PrefaceMain from '/src/components/@sections/PrefaceMain'
// import RariGames from '/src/components/@sections/RariGames'
import LatestCollections from '/src/components/@sections/LatestCollections'
import TopCollections from '/src/components/@sections/TopCollections'
import Upcoming from '/src/components/@sections/Upcoming'
// import JoinUs from '/src/components/@sections/JoinUs'
import MainStats from '/src/components/@sections/MainStats'
import ChromeExtension from '/src/components/@sections/ChromeExtension'
import Rabbitzz from '/src/components/@sections/Rabbitzz'

const HomePage = ({
  collections = [],
  featuredCollections = [],
  upcomingCollections = [],
  latestCollections = [],
}) => {
  return (
    <Layout
      title='NFT Rarity Explorer'
      description='The best free rarity tool to check the ranking of 1000+ NFT collections on Ethereum, Solana & other blockchains.'
      headerTheme='ghost'
      isHome={true}
      className='main-page'
    >
      <PrefaceMain />
      <MainStats />
      <LatestCollections collections={latestCollections} />
      <ChromeExtension />
      <TopCollections
        collections={collections}
        featuredCollections={featuredCollections}
      />
      <Rabbitzz />
      <Upcoming data={upcomingCollections} />
      {/*<JoinUs />*/}
    </Layout>
  )
}

export async function getServerSideProps({ res }) {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  )
  return {
    props: {
      collections: await CoreApi.getCollections({
        sort: 'volumeD',
        size: 4,
        min: 1,
      }),
      latestCollections: await CoreApi.getCollections({
        size: 9,
        sort: '-createdAt',
      }),
      featuredCollections: await CoreApi.getCollections({
        size: 10,
        featured: 1,
        min: 1,
      }),
      upcomingCollections: await CoreApi.getUpcomingCollections({
        size: 8,
        saleDateTo: new Date().setUTCHours(23, 59, 59, 999),
        sort: 'saleDateDesc',
      }),
    },
  }
}

export default HomePage
