import PropTypes from 'prop-types'

const Tag = ({ color, className, children }) => {
  const tagClassName =
    `tag tag_color_${color}` + (className ? ` ${className}` : '')

  return <div className={tagClassName}>{children}</div>
}

Tag.defaultProps = {
  color: 'default',
  children: '',
}

Tag.PropTypes = {
  color: PropTypes.oneOf(['default']),
  children: PropTypes.any,
  className: PropTypes.string,
}

export default Tag
