import { useContext } from 'react'
import PropTypes from 'prop-types'

import Title from '/src/components/@common.blocks/Title'
import DeviceContext from '/src/contexts/DeviceContext'

const getValue = (row, key) => {
  const keys = key.split('.')
  return keys.reduce((obj, el) => {
    return obj ? obj[el] : '-'
  }, row)
}

const formatValue = (val, props) => {
  let res = val
  switch (typeof res) {
    case 'number': {
      const decimals = typeof props.decimals !== 'number' ? 4 : props.decimals
      return Number(res).toFixed(decimals)
    }
    default:
      return res
  }
}

const Table = ({
  title,
  columns,
  data,
  extra,
  onRowClick,
  size,
  pagination,
  className,
}) => {
  const { isMobile } = useContext(DeviceContext)

  const tableClassName =
    'table' +
    (className ? ` ${className}` : '') +
    (size ? ` table_size_${size}` : '')

  return (
    <div className='table-wrapper'>
      <div className='table-head'>
        <Title level={2}>{title}</Title>
        {extra && <div className='table-head__extra'>{extra}</div>}
      </div>
      <table className={tableClassName}>
        <thead>
          <tr>
            {columns
              .filter((column) => {
                if (
                  column.responsive &&
                  column.responsive.indexOf('md') >= 0 &&
                  isMobile
                ) {
                  return false
                }
                return true
              })
              .map((column) => (
                <th key={column.id}>{column.title}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, key) => {
            const onClick = onRowClick ? (e) => onRowClick(e, row) : undefined
            return (
              <tr key={key} onClick={onClick}>
                {columns
                  .filter((column) => {
                    if (
                      column.responsive &&
                      column.responsive.indexOf('md') >= 0 &&
                      isMobile
                    ) {
                      return false
                    }
                    return true
                  })
                  .map((column) => (
                    <td key={column.id} data-th={`${column.title}:`}>
                      {column.render
                        ? column.render(row, key)
                        : formatValue(getValue(row, column.id), column) || ''}
                    </td>
                  ))}
              </tr>
            )
          })}
        </tbody>
      </table>
      {pagination && <div className='table__pagination'>{pagination}</div>}
    </div>
  )
}

Table.defaultProps = {
  title: '',
  columns: [],
  data: [],
  size: 'default',
  pagination: '',
}

Table.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  columns: PropTypes.array,
  data: PropTypes.array,
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.oneOf(['default', 'middle', 'small']),
  pagination: PropTypes.any,
  onRowClick: PropTypes.func,
}

export default Table
