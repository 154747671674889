import Link from 'next/link'
import Image from 'next/image'

import Container from '/src/components/@common.blocks/Container'
import Title from '/src/components/@common.blocks/Title'
import Search from '/src/components/@common.blocks/Search'
import Text from '/src/components/@common.blocks/Text'

import prefaceImage from '/public/images/preface-background.png'

const PrefaceMain = () => {
  return (
    <section className='preface-main' id='explore'>
      <div className='preface-main__cover'>
        <Image
          src={prefaceImage}
          alt='background'
          className='preface-main__bg'
          layout='fill'
        />
      </div>

      <Container size='xs' className='preface-main__container'>
        <Title level={1} className='preface-main__title'>
          {`Discover the `}
          <Text color='rainbow' component='span' size='inherit' weight='bold'>
            Metaverse
          </Text>
        </Title>
        <Search
          placeholder='Example: MoonBirds'
          responsive={false}
          shape='square'
        />
        <Link href='/collections'>
          <a className='text text_color_light-gray text_weight_medium text_size_md text-underline text-center'>
            or explore
          </a>
        </Link>
      </Container>
    </section>
  )
}

export default PrefaceMain
