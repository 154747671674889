import { useState, useEffect, useContext } from 'react'
import Image from 'next/image'
import moment from 'moment'
import numeral from 'numeral'

import CardHeader from './CardHeader'
import CardFooter from './CardFooter'
import Skeleton from '/src/components/@common.blocks/Skeleton'

import blockchainIcons from '/src/constants/blockchainIcons'
import blockchainSymbols from '/src/constants/blockchainSymbols'
import DeviceContext from '/src/contexts/DeviceContext'

const PLACEHOLDER_SRC = '/images/placeholder.svg'

const UpcomingCard = ({ collection }) => {
  const [imageSrc, setImageSource] = useState()
  const [loading, setLoading] = useState(true)
  const { isMobile } = useContext(DeviceContext)

  useEffect(() => {
    if (collection) {
      setImageSource(
        collection.previewImages && collection.previewImages.length > 0
          ? collection.previewImages[0]
          : collection.coverImage
      )
    } else {
      setImageSource(PLACEHOLDER_SRC)
    }
  }, [collection])

  const saleDate = collection.saleDate
    ? moment(collection.saleDate).format('MMM D')
    : '-'

  const saleTime = collection.saleDate
    ? moment(collection.saleDate).format('h a')
    : ''

  const isMinting =
    collection.isMinting === true ||
    (collection.saleDate &&
      new Date(collection.saleDate).getTime() <= new Date().getTime() &&
      new Date(collection.saleDate).getTime() + 12 * 60 * 60 * 1000 >=
        new Date().getTime())

  const supply = numeral(collection.supply).format('0.[0]a')

  return (
    <article className='upcoming-card'>
      {collection.featured && (
        <div className='upcoming-card__promo'>
          <img src='/icons/promo.svg' alt='promo' height={16} width={16} />
          <span className='text_color_gold text_weight_bold text-uppercase'>
            promo
          </span>
        </div>
      )}
      <div className='upcoming-card__blockchain'>
        <Image
          src={blockchainIcons[collection.blockchain]}
          alt={collection.blockchain}
          height={14}
          width={14}
        />
        {blockchainSymbols[collection.blockchain]}
      </div>
      <div className='upcoming-card__cover'>
        {imageSrc && (
          <Skeleton loading={loading}>
            <Image
              src={imageSrc}
              alt={collection.name}
              layout='fill'
              width={250}
              height={250}
              onLoadingComplete={() => setLoading(false)}
              onError={() => setImageSource(PLACEHOLDER_SRC)}
            />
          </Skeleton>
        )}
        {isMinting && (
          <div className='upcoming-card__status'>
            <div className='upcoming-card__status--mint'>
              <div className='upcoming-card__online'>
                <div className='upcoming-card__online-pulse' />
              </div>
              Mint Now
            </div>
          </div>
        )}
      </div>
      <div className='upcoming-card__container'>
        <CardHeader
          name={collection.name}
          blockchain={collection.blockchain}
          featured={collection.featured}
          supply={supply}
          saleDate={saleDate}
          saleTime={saleTime}
          mintPrice={collection.mintPrice}
          isMobile={isMobile}
        />
        <CardFooter
          website={collection.website}
          twitter={collection.twitter}
          discord={collection.discord}
          isMobile={isMobile}
        />
      </div>
    </article>
  )
}

export default UpcomingCard
