import PropTypes from 'prop-types'
import Col from './Col'

const Grid = ({ children, gutter, className }) => {
  let classNameGrid = 'grid'

  if (typeof gutter === 'object') {
    const breakpoints = Object.keys(gutter)
    for (let bp of breakpoints) {
      classNameGrid = classNameGrid + ` g-gutter-${bp}-${gutter[bp]}`
    }
  } else if (typeof gutter === 'number') {
    classNameGrid = classNameGrid + ` g-gutter-${gutter}`
  }

  if (className) {
    classNameGrid = classNameGrid + ` ${className}`
  }

  return <div className={classNameGrid}>{children}</div>
}

Grid.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  gutter: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
}

Grid.Col = Col
export default Grid
