import { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'

import Container from '/src/components/@common.blocks/Container'
import Title from '/src/components/@common.blocks/Title'
import LatestCard from '/src/components/@common.blocks/LatestCard'

const LatestCollections = ({ collections }) => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const elContainer = document.querySelector(
        '.latest-collections__swiper-container'
      )
      const elPrev = document.querySelector(
        '.latest-collections__swiper .swiper-button-prev'
      )
      const elNext = document.querySelector(
        '.latest-collections__swiper .swiper-button-next'
      )
      if (!elContainer || !elPrev || !elNext) return

      elContainer.appendChild(elPrev)
      elContainer.appendChild(elNext)

      const elDots = document.querySelector(
        '.latest-collections__swiper .swiper-pagination'
      )
      if (elDots) {
        elContainer.appendChild(elDots)
      }
    }
  }, [])

  return (
    <section className='latest-collections' id='latest'>
      <Container className='latest-collections__container'>
        <Title level={2} className='section__title text-left'>
          Recently Ranked
        </Title>
        <div className='latest-collections__swiper-container'>
          <Swiper
            className='latest-collections__swiper'
            modules={[Navigation, Pagination]}
            spaceBetween={20}
            navigation
            scrollbar={{ draggable: true }}
            pagination={{ clickable: true }}
            slidesPerView={1}
            slidesPerGroup={1}
            breakpoints={{
              640: {
                slidesPerView: 2,
                slidesPerGroup: 2,
              },
              991: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
            }}
            loop={false}
          >
            {collections.map((collection, key) => (
              <SwiperSlide key={key}>
                <LatestCard data={collection} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Container>
    </section>
  )
}

export default LatestCollections
